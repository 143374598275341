import { render, staticRenderFns } from "./Admin.vue?vue&type=template&id=7ce0c570"
import script from "./Admin.vue?vue&type=script&lang=js"
export * from "./Admin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseMenu: require('/var/www/web134/htdocs/staging.lpm.pl-x.de/deployer/releases/20240905073554/frontend/components/base/Menu.vue').default})
