import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2e41ecb8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a8711cde = () => interopDefault(import('../pages/auth/forgotten.vue' /* webpackChunkName: "pages/auth/forgotten" */))
const _7f4302e0 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _5e5dcdc6 = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _aa2ba2ce = () => interopDefault(import('../pages/auth/welcome.vue' /* webpackChunkName: "pages/auth/welcome" */))
const _069bc255 = () => interopDefault(import('../modules/users/pages/index.vue' /* webpackChunkName: "" */))
const _16de59ee = () => interopDefault(import('../modules/users/pages/create.vue' /* webpackChunkName: "" */))
const _2fa44257 = () => interopDefault(import('../modules/users/pages/edit.vue' /* webpackChunkName: "" */))
const _93ff32b6 = () => interopDefault(import('../modules/stores/pages/index.vue' /* webpackChunkName: "" */))
const _6c1c57f2 = () => interopDefault(import('../modules/stores/pages/edit.vue' /* webpackChunkName: "" */))
const _419242a0 = () => interopDefault(import('../modules/landingPages/pages/index.vue' /* webpackChunkName: "" */))
const _186a5c1e = () => interopDefault(import('../modules/landingPages/pages/create.vue' /* webpackChunkName: "" */))
const _3d1aa452 = () => interopDefault(import('../modules/landingPages/pages/create/base.vue' /* webpackChunkName: "" */))
const _4f47380c = () => interopDefault(import('../modules/landingPages/pages/create/final-order-confirmation.vue' /* webpackChunkName: "" */))
const _2e748480 = () => interopDefault(import('../modules/landingPages/pages/create/cross-sell.vue' /* webpackChunkName: "" */))
const _417bed83 = () => interopDefault(import('../modules/landingPages/pages/create/sepa-enhancement.vue' /* webpackChunkName: "" */))
const _c8cf5ca4 = () => interopDefault(import('../modules/landingPages/pages/create/upsell.vue' /* webpackChunkName: "" */))
const _2491c34a = () => interopDefault(import('../modules/landingPages/pages/create/video.vue' /* webpackChunkName: "" */))
const _9f5dbe2c = () => interopDefault(import('../modules/landingPages/pages/create/email-signup.vue' /* webpackChunkName: "" */))
const _b34f7b86 = () => interopDefault(import('../modules/landingPages/pages/create/webinar-registration.vue' /* webpackChunkName: "" */))
const _efb703b0 = () => interopDefault(import('../modules/landingPages/pages/create/free-signup.vue' /* webpackChunkName: "" */))
const _0496cfd7 = () => interopDefault(import('../modules/landingPages/pages/create/multi-offer.vue' /* webpackChunkName: "" */))
const _465a7a8c = () => interopDefault(import('../modules/landingPages/pages/create/appointment-booking.vue' /* webpackChunkName: "" */))
const _c95f44ee = () => interopDefault(import('../modules/credentials/pages/index.vue' /* webpackChunkName: "" */))
const _361ff9d2 = () => interopDefault(import('../modules/credentials/pages/new.vue' /* webpackChunkName: "" */))
const _6dd51eba = () => interopDefault(import('../modules/credentials/pages/edit.vue' /* webpackChunkName: "" */))
const _c19581c4 = () => interopDefault(import('../modules/fileManager/pages/index.vue' /* webpackChunkName: "" */))
const _be44d7d4 = () => interopDefault(import('../modules/tenants/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _2e41ecb8,
    name: "index___en"
  }, {
    path: "/auth/forgotten",
    component: _a8711cde,
    name: "auth-forgotten___de"
  }, {
    path: "/auth/login",
    component: _7f4302e0,
    name: "auth-login___de"
  }, {
    path: "/auth/reset",
    component: _5e5dcdc6,
    name: "auth-reset___de"
  }, {
    path: "/auth/welcome",
    component: _aa2ba2ce,
    name: "auth-welcome___de"
  }, {
    path: "/en/auth/forgotten",
    component: _a8711cde,
    name: "auth-forgotten___en"
  }, {
    path: "/en/auth/login",
    component: _7f4302e0,
    name: "auth-login___en"
  }, {
    path: "/en/auth/reset",
    component: _5e5dcdc6,
    name: "auth-reset___en"
  }, {
    path: "/en/auth/welcome",
    component: _aa2ba2ce,
    name: "auth-welcome___en"
  }, {
    path: "/",
    component: _2e41ecb8,
    name: "index___de"
  }, {
    path: "/users",
    component: _069bc255,
    name: "users___de"
  }, {
    path: "/en/users",
    component: _069bc255,
    name: "users___en"
  }, {
    path: "/users/create",
    component: _16de59ee,
    name: "users-create___de"
  }, {
    path: "/en/users/create",
    component: _16de59ee,
    name: "users-create___en"
  }, {
    path: "/users/edit",
    component: _2fa44257,
    name: "users-edit___de"
  }, {
    path: "/en/users/edit",
    component: _2fa44257,
    name: "users-edit___en"
  }, {
    path: "/stores",
    component: _93ff32b6,
    name: "stores___de"
  }, {
    path: "/en/stores",
    component: _93ff32b6,
    name: "stores___en"
  }, {
    path: "/stores/edit",
    component: _6c1c57f2,
    name: "stores-edit___de"
  }, {
    path: "/en/stores/edit",
    component: _6c1c57f2,
    name: "stores-edit___en"
  }, {
    path: "/landingpages",
    component: _419242a0,
    name: "landingpages___de"
  }, {
    path: "/en/landingpages",
    component: _419242a0,
    name: "landingpages___en"
  }, {
    path: "/landingpages/create",
    component: _186a5c1e,
    name: "landingpages-create___de"
  }, {
    path: "/en/landingpages/create",
    component: _186a5c1e,
    name: "landingpages-create___en"
  }, {
    path: "/landingpages/create/base",
    component: _3d1aa452,
    name: "landingpages-create-base___de"
  }, {
    path: "/en/landingpages/create/base",
    component: _3d1aa452,
    name: "landingpages-create-base___en"
  }, {
    path: "/landingpages/create/final-order-confirmation",
    component: _4f47380c,
    name: "landingpages-create-final-order-confirmation___de"
  }, {
    path: "/en/landingpages/create/final-order-confirmation",
    component: _4f47380c,
    name: "landingpages-create-final-order-confirmation___en"
  }, {
    path: "/landingpages/create/cross-sell",
    component: _2e748480,
    name: "landingpages-create-cross-sell___de"
  }, {
    path: "/en/landingpages/create/cross-sell",
    component: _2e748480,
    name: "landingpages-create-cross-sell___en"
  }, {
    path: "/landingpages/create/sepa-enhancement",
    component: _417bed83,
    name: "landingpages-create-sepa-enhancement___de"
  }, {
    path: "/en/landingpages/create/sepa-enhancement",
    component: _417bed83,
    name: "landingpages-create-sepa-enhancement___en"
  }, {
    path: "/landingpages/create/upsell",
    component: _c8cf5ca4,
    name: "landingpages-create-upsell___de"
  }, {
    path: "/en/landingpages/create/upsell",
    component: _c8cf5ca4,
    name: "landingpages-create-upsell___en"
  }, {
    path: "/landingpages/create/video",
    component: _2491c34a,
    name: "landingpages-create-video___de"
  }, {
    path: "/en/landingpages/create/video",
    component: _2491c34a,
    name: "landingpages-create-video___en"
  }, {
    path: "/landingpages/create/email-signup",
    component: _9f5dbe2c,
    name: "landingpages-create-email-signup___de"
  }, {
    path: "/en/landingpages/create/email-signup",
    component: _9f5dbe2c,
    name: "landingpages-create-email-signup___en"
  }, {
    path: "/landingpages/create/webinar-registration",
    component: _b34f7b86,
    name: "landingpages-create-webinar-registration___de"
  }, {
    path: "/en/landingpages/create/webinar-registration",
    component: _b34f7b86,
    name: "landingpages-create-webinar-registration___en"
  }, {
    path: "/landingpages/create/free-signup",
    component: _efb703b0,
    name: "landingpages-create-free-signup___de"
  }, {
    path: "/en/landingpages/create/free-signup",
    component: _efb703b0,
    name: "landingpages-create-free-signup___en"
  }, {
    path: "/landingpages/create/multi-offer",
    component: _0496cfd7,
    name: "landingpages-create-multi-offer___de"
  }, {
    path: "/en/landingpages/create/multi-offer",
    component: _0496cfd7,
    name: "landingpages-create-multi-offer___en"
  }, {
    path: "/landingpages/create/appointment-booking",
    component: _465a7a8c,
    name: "landingpages-create-appointment-booking___de"
  }, {
    path: "/en/landingpages/create/appointment-booking",
    component: _465a7a8c,
    name: "landingpages-create-appointment-booking___en"
  }, {
    path: "/credentials",
    component: _c95f44ee,
    name: "credentials___de"
  }, {
    path: "/en/credentials",
    component: _c95f44ee,
    name: "credentials___en"
  }, {
    path: "/credentials/new",
    component: _361ff9d2,
    name: "credentials-new___de"
  }, {
    path: "/en/credentials/new",
    component: _361ff9d2,
    name: "credentials-new___en"
  }, {
    path: "/credentials/edit",
    component: _6dd51eba,
    name: "credentials-edit___de"
  }, {
    path: "/en/credentials/edit",
    component: _6dd51eba,
    name: "credentials-edit___en"
  }, {
    path: "/filemanager",
    component: _c19581c4,
    name: "filemanager___de"
  }, {
    path: "/en/filemanager",
    component: _c19581c4,
    name: "filemanager___en"
  }, {
    path: "/tenants",
    component: _be44d7d4,
    name: "tenants___de"
  }, {
    path: "/en/tenants",
    component: _be44d7d4,
    name: "tenants___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
